<template>
    <div class='addCharge-container summarCollection-container' @click.stop="tenantRoom = false,tenantVisible = false">
        <CSDialog
                :dialogTitle="dialogTitle"
                dialogWidth="766px"
                :dialogVisible="addRoomVisibleHy"
                @onClose="addRoomVisibleHy = false"
                @onConfirm="editRoomHy"
                dialog-confirm-btn-text="保存">
            <div slot="dialog-content" class="room-form" @click.stop="tenantRoom = false,tenantVisible = false">
                <div class="field-item jsF" v-show="!isUpdateK">
                    <label class="field-item-label">所属公摊汇总</label>
                    <div class="field-item-content">
                        <CSSelect height="40px" iWidth="36px">
                            <select
                                    v-model="infor"
                                    @change="changeBuilding"
                                    style="width: 388px"
                                    :style="{
                                        color:infor?'black':'#999'
                                    }"
                            >
                                <option value="">请选择</option>
                                <option
                                        v-for="building in inforList"
                                        :key="building.id"
                                        :value="building"
                                >
                                    {{
                                    `${building.equallySharedName}(${building.collectBillStartDate}-${building.collectBillEndDate})`
                                    }}
                                </option>
                            </select>
                        </CSSelect>
                    </div>
                </div>
                <div class="field-item">
                    <label class="field-item-label">计费起止日期</label>
                    <div class="field-item-content">
                        <template v-if="!isUpdateK">
                            <div>
                                <CSSelect i-width="40px" style="width: 180px; margin-right: 0;height: 40px;">
                                    <el-date-picker
                                            type="datetime"
                                            placeholder="请选择开始时间"
                                            v-model="editRoomInfo.billStartDate"
                                            style="width: 170px;font-size: 20px"
                                            format="yyyy-MM-dd"
                                            value-format="yyyy-MM-dd"
                                            prefix-icon="el-icon-time"
                                            :editable="false"
                                    >
                                    </el-date-picker>
                                </CSSelect>
                                <span class="inline-gray"> </span>
                                <CSSelect i-width="40px" style="width: 180px;height: 40px">
                                    <el-date-picker
                                            type="datetime"
                                            placeholder="请选择结束时间"
                                            v-model="editRoomInfo.billEndDate"
                                            style="width: 190px;font-size: 20px"
                                            format="yyyy-MM-dd"
                                            value-format="yyyy-MM-dd"
                                            prefix-icon="el-icon-time"
                                            :editable="false"
                                    >
                                    </el-date-picker>
                                </CSSelect>
                            </div>
                        </template>
                        <template v-else>
                            {{`${editRoomInfo.billStartDate}~${editRoomInfo.billEndDate}`}}
                        </template>
                    </div>
                </div>

                <div class="field-item">
                    <label class="field-item-label">{{isElectricty?'公摊总用电量(度)':'公摊总用水量(吨)'}}</label>
                    <div class="field-item-content">
                        <template v-if="!isUpdateK">
                            <input
                                    v-model="editRoomInfo.equallySharedDosage"
                                    type="Number"
                                    placeholder="请输入"
                            />
                        </template>
                        <template v-else>
                            {{editRoomInfo.equallySharedDosage}}{{isElectricty?'度':'吨'}}
                        </template>

                    </div>
                </div>
                <div class="field-item">
                    <label class="field-item-label">{{isElectricty?'公摊电价(元/度)':'公摊水价(元/吨)'}}</label>
                    <div class="field-item-content">
                        <template v-if="!isUpdateK">
                            <input
                                    v-model="editRoomInfo.equallySharedPrice"
                                    type="Number"
                                    placeholder="请输入"
                                    @input="numberCheck(editRoomInfo,'equallySharedPrice',$event)"
                            />
                        </template>
                        <template v-else>
                            {{editRoomInfo.equallySharedPrice}}{{isElectricty?'元/度':'元/吨'}}
                        </template>
                    </div>
                </div>


                <div class="field-item jsF">
                    <label class="field-item-label">{{isElectricty?'公摊电费总额':'公摊水费总额'}}</label>
                    <div class="field-item-content">
                        {{equallySharedTotalAmount || '0'}}元
                    </div>
                </div>
                <div class="field-item jsF">
                    <label class="field-item-label">{{isElectricty?'电费公摊总面积':'水费公摊总面积'}}</label>
                    <div class="field-item-content">
                        {{totalSpace || '0'}}m²
                    </div>
                </div>
                <div class="field-item jsF">
                    <label class="field-item-label">{{isElectricty?'单位面积公摊费':'单位面积公摊费'}}</label>
                    <div class="field-item-content">
                        {{equallySharedPriceAvg || '0'}}元/㎡
                    </div>
                </div>
                <div class="field-item jsF" style="position: relative">
                    <label class="field-item-label">房间号</label>
                    <div class="field-item-content">
                        <template v-if="!isUpdateK">
                            <div class="field-item-content lable-coin" style="width: 430px;vertical-align: top">
                                <CSSelect height="40px" iWidth="36px" style="margin-bottom: 0px;">
                                    <div
                                            style="
                                          font-size: 24px;
                                          color: #999;
                                          margin-left: 10px;
                                          width: 354px;
                                        "
                                            @click.stop="tenantRoom = !tenantRoom"

                                    >
                                        <span v-if="!editRoomInfo.roomNo">请选择</span>
                                        <div v-else style="color: black;white-space: nowrap">
                                            {{`${editRoomInfo.buildingName}-${editRoomInfo.floor}层-${editRoomInfo.roomNo}`}}
                                        </div>
                                    </div>
                                    <TreePanel v-show="tenantRoom" width="310px" style="left: 248px">
                                        <div>
                                            <input
                                                    type="text"
                                                    placeholder="搜索"
                                                    style="
                                  width: 200px;
                                  height: 40px;
                                  padding-left: 10px;
                                  font-size: 20px;
                                  border: 1px solid #999;
                                  border-radius: 4px;
                                  vertical-align: middle;
                                "
                                                    v-model="queryRooms.roomNo"
                                            />
                                            <button
                                                    type="button"
                                                    class="btn btn-primary"
                                                    style="
                                                  margin-left: 10px;
                                                  width: 70px;
                                                  height: 40px;
                                                  font-size: 24px;
                                                  padding: 0 0 2px 0;
                                                  vertical-align: middle;
                                                "
                                                    @click="queryTenantRoom"
                                            >
                                                查询
                                            </button>
                                        </div>
                                        <div style="overflow-y: auto; font-size: 20px">
                                            <div
                                                    v-for="lessee in roomList"
                                                    :key="lessee.id"
                                                    style="border-bottom: 1px solid #f0f0f0"
                                                    @click="addCompanyNameRoom(lessee.roomNo, lessee)"
                                            >
                                                <div style="margin: 5px 10px">{{
                                                    `${lessee.buildingName}-${lessee.floor}层-${lessee.roomNo}` }}
                                                </div>
                                            </div>
                                        </div>
                                    </TreePanel>
                                </CSSelect>
                            </div>
                        </template>
                        <template v-else>
                            {{`${editRoomInfo.buildingName}-${editRoomInfo.floor}层-${editRoomInfo.roomNo}`}}
                        </template>
                    </div>
                </div>
                <div class="field-item jsF">
                    <label class="field-item-label">水电公摊承担面积</label>
                    <div class="field-item-content">
                        {{editRoomInfo.roomSpace || '-'}}m²
                    </div>
                </div>
                <div class="field-item jsF">
                    <label class="field-item-label">账单金额</label>
                    <div class="field-item-content">
                        <template v-if="!isUpdateK">
                            {{billAmount}}
                        </template>
                        <template v-else>
                            <input
                                    v-model="billAmountData"
                                    type="Number"
                                    placeholder="请输入"
                                    style="width: 150px"
                                    @input="inputPla"
                            /> 元
                        </template>
                    </div>
                </div>
                <div class="field-item jsF" style="position: relative">
                    <label class="field-item-label">水电公摊承担方</label>
                    <div class="field-item-content">
                        <div class="field-item-content lable-coin" style="width: 430px;vertical-align: top">
                            <CSSelect height="40px" iWidth="36px" style="margin-bottom: 0px;">
                                <div
                                        style="
                                          font-size: 24px;
                                          color: #999;
                                          margin-left: 10px;
                                          width: 354px;
                                        "
                                        @click.stop="tenantVisible = !tenantVisible"
                                >
                                    <span v-if="!editRoomInfo.assumeLesseeName">请选择</span>
                                    <div v-else style="color: black;white-space: nowrap">
                                        {{editRoomInfo.assumeLesseeName}}
                                    </div>
                                </div>
                                <TreePanel v-show="tenantVisible" width="310px" style="left: 248px">
                                    <div>
                                        <input
                                                type="text"
                                                placeholder="搜索"
                                                style="
                                  width: 200px;
                                  height: 40px;
                                  padding-left: 10px;
                                  font-size: 20px;
                                  border: 1px solid #999;
                                  border-radius: 4px;
                                  vertical-align: middle;
                                "
                                                v-model="keyword"
                                        />
                                        <button
                                                type="button"
                                                class="btn btn-primary"
                                                style="
                                                  margin-left: 10px;
                                                  width: 70px;
                                                  height: 40px;
                                                  font-size: 24px;
                                                  padding: 0 0 2px 0;
                                                  vertical-align: middle;
                                                "
                                                @click="queryTenant"
                                        >
                                            查询
                                        </button>
                                    </div>
                                    <div style="overflow-y: auto; font-size: 20px">
                                        <div
                                                v-for="lessee in lesseeList"
                                                :key="lessee.id"
                                                style="border-bottom: 1px solid #f0f0f0"
                                                @click="addCompanyName(lessee.companyName, lessee.id)"
                                        >
                                            <div style="margin: 5px 10px">{{ lessee.companyName }}</div>
                                            <div style="margin: 5px 10px">
                                                <span>{{ lessee.deputyName }}</span>
                                                <span>{{ " 【" + lessee.deputyPhone + "】" }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </TreePanel>
                            </CSSelect>
                        </div>
                    </div>
                </div>
                <div class="tip" style="font-size: 20px;color: #999">
                    <svg
                            class="icon"
                            aria-hidden="true"
                    >
                        <use xlink:href="#icon-menua-zu92"></use>
                    </svg>
                    账单产生后不可撤销，请仔细核对填写内容。
                </div>

            </div>

        </CSDialog>
    </div>
</template>

<script>
    import CSDialog from "@/components/common/CSDialog";
    import CSSelect from "@/components/common/CSSelect";
    import TreePanel from "@/components/common/TreePanel";
    import {
        queryTenantUrl,
        queryEquallySharedCollectUrl,
        queryRoomRentUrl,
        queryRoomRentUrls,
        addEquallySharedBillUrl,
        modifyEquallySharedBillUrl
    } from "@/requestUrl";
    import {BigNumber} from "@/utils/bignumber.js";

    export default {
        name: "AddChargeCS",
        props: {
            isElectricty: {
                default: false
            },
            dialogTitle: {
                default: "添加水费公摊账单"
            }
        },
        async created() {
            this.queryTenant();
            await this.queryH();
            this.$vc.on('addRoomVisibleHy', (item) => {
                this.billAmountData = null;
                this.isUpdateK = false;
                this.addRoomVisibleHy = true;
                if (!item) {
                    this.infor = "";
                    this.editRoomInfo = {
                        "userId": this.$vc.getCurrentStaffInfo().userId,  //用户ID
                        "userName": this.$vc.getCurrentStaffInfo().name, //用户名称
                        "regionCode": this.$vc.getCurrentRegion().code, // 园区code
                        "equallySharedCollectId": '', //公摊汇总表ID
                        "equallySharedCollectName": "", //公摊汇总名称
                        "billGenerateWay": "", //账单产生方式   0-自动 1-手动 该接口恒等于1
                        "billStartDate": "",  // 账单计费开始日期
                        "billEndDate": "",  // 账单计费截止日期
                        "roomNo": "", //房间编号
                        "roomId": "", //房间ID
                        "equallySharedPriceAvg": "", // 单位面积公摊费
                        "equallySharedTotalAmount": "",  //公摊水费/电费总额
                        "equallySharedPrice": "", //公摊水价/电价
                        "equallySharedDosage": "", // 公摊总用水用电量
                        "type": this.isElectricty ? 2 : 1, //类型  1-水 2-电
                        "roomSpace": "",  // 房屋建筑面积
                        "buildingName": "", //楼栋名称
                        "buildingCode": "",// 楼栋code
                        "assumeLesseeId": "", //账单承担公司ID
                        "assumeLesseeName": "",  // 账单承担公司名称
                        "billState": 0, // 账单状态  0-正常 1-作废 2-删除
                        "alreadyPayState": 0, //账单缴费状态  0-未下发 1-已缴费 2-未缴费
                        "billAmount": "", // 账单金额
                        "floor": ""
                    }
                } else {
                    this.id = item.id;
                    console.log(item);
                    this.infor = this.inforList.filter(res => {
                        return item.equallySharedCollectId == res.id
                    })[0];
                    this.totalSpace = this.infor.equallySharedSpace;
                    this.editRoomInfo = {
                        "userId": this.$vc.getCurrentStaffInfo().userId,  //用户ID
                        "userName": this.$vc.getCurrentStaffInfo().name, //用户名称
                        "regionCode": this.$vc.getCurrentRegion().code, // 园区code
                        ...item,

                    }
                    if (item.billGenerateWay == 0 || (item.alreadyPayState == 2 && item.billGenerateWay == 1)) {
                        this.isUpdateK = true;
                        this.billAmountData = item.billAmount;
                    }

                }
            })

            //查询所有房间信息
            this.queryRoom();
        },
        data() {
            return {
                isEditRoomHyTimer:null,
                isEditRoomHy:false,
                billAmountData: null,
                isUpdateK: false,
                totalSpace: "",
                // ${lessee.buildingName}-${lessee.floor}层-${lessee.roomNo},
                lessee: {
                    buildingName: '',
                    floor: '',
                },
                id: '',
                tenantRoom: false,
                roomList: [],
                queryRooms: {
                    buildingId: "",
                    roomNo: "",
                    state: "",
                    floor: "",
                    isDivision: ""
                },
                lesseeList: [],
                keyword: '',
                tenantVisible: false,
                addRoomVisibleHy: false,
                infor: '',//工单信息
                inforList: [],
                editRoomInfo: {
                    "userId": this.$vc.getCurrentStaffInfo().userId,  //用户ID
                    "userName": this.$vc.getCurrentStaffInfo().name, //用户名称
                    "regionCode": this.$vc.getCurrentRegion().code, // 园区code
                    "equallySharedCollectId": '', //公摊汇总表ID
                    "equallySharedCollectName": "", //公摊汇总名称
                    "billGenerateWay": "", //账单产生方式   0-自动 1-手动 该接口恒等于1
                    "billStartDate": "",  // 账单计费开始日期
                    "billEndDate": "",  // 账单计费截止日期
                    "roomNo": "", //房间编号
                    "roomId": "", //房间ID
                    "equallySharedPriceAvg": "", // 单位面积公摊费
                    "equallySharedTotalAmount": "",  //公摊水费/电费总额
                    "equallySharedPrice": "", //公摊水价/电价
                    "equallySharedDosage": "", // 公摊总用水用电量
                    "type": this.isElectricty ? 2 : 1, //类型  1-水 2-电
                    "roomSpace": "",  // 房屋建筑面积
                    "buildingName": "", //楼栋名称
                    "buildingCode": "",// 楼栋code
                    "assumeLesseeId": "", //账单承担公司ID
                    "assumeLesseeName": "",  // 账单承担公司名称
                    "billState": 0, // 账单状态  0-正常 1-作废 2-删除
                    "alreadyPayState": 0, //账单缴费状态  0-未下发 1-已缴费 2-未缴费
                    "billAmount": "", // 账单金额
                    "floor": ""//楼层
                }
            }
        },
        components: {
            CSDialog,
            CSSelect,
            TreePanel
        },
        computed: {
            equallySharedPriceAvg() {
                if (this.totalSpace && this.totalSpace > 0) {
                    if((this.equallySharedTotalAmount / this.totalSpace) == 0){
                        return 0
                    }
                    return (this.equallySharedTotalAmount / this.totalSpace).toFixed(8);
                } else {
                    return 0
                }
            },
            equallySharedTotalAmount() {
                if (this.editRoomInfo.equallySharedPrice || this.editRoomInfo.equallySharedPrice) {
                    return this.numberUtil.numberMul(this.editRoomInfo.equallySharedDosage, this.editRoomInfo.equallySharedPrice);
                }
                return 0;
            },
            billAmount() {
                if (this.editRoomInfo.roomSpace && this.equallySharedPriceAvg) {
                    return this.numberUtil.numberMul(this.editRoomInfo.roomSpace, this.equallySharedPriceAvg).toFixed(2);
                }
                return 0
            }
        },
        methods: {
            inputPla() {
                this.billAmountData =  this.billAmountData.replace(
                    /^(-)*(\d+)\.(\d\d).*$/,
                    "$1$2.$3"
                ); // 只能输入两位小数
            },
            /**
             * 限制只允许输入两位小数
             * type 为number
             * item 为json对象
             * keyname为键值
             * event为事件对象.两位小数
             */
            numberCheck(item, keyname, event) {
                var value = item[keyname];
                var zeroFirst = value.indexOf(0);
                var dot = value.indexOf('.');
                var vlen = value.length
                if (value.indexOf('.') == 0) {
                    this.$message.error("请输入合法的数字!");
                    item[keyname] = 0;
                }
                if (zeroFirst === 0) {//首位为0,第二位则必须为.
                    if (vlen >= 3 && value.indexOf('.') == 1) {
                        if (value.length > 4) {
                            item[keyname] = value.substr(0, vlen - 1);
                            this.$message.error("最多两位小数!");
                        } else {
                            if (isNaN(Number(value[vlen - 1]))) {
                                item[keyname] = value.substr(0, vlen - 1);
                                this.$message.error("请输入合法的数字!");
                            }
                        }
                    } else if (vlen == 2 && value.indexOf('.') != 1) {
                        this.$message.error("请输入小数点!");
                        item[keyname] = 0;
                    }
                } else {
                    if (dot > -1 && dot + 1 != vlen) {//已输入小数点
                        if (isNaN(Number(value[vlen - 1]))) {//小数点后只能输入0-9
                            item[keyname] = value.substr(0, vlen - 1);
                            this.$message.error("请输入合法的数字0-9!");
                        } else if (vlen - dot > 2 + 1) {
                            item[keyname] = value.substr(0, vlen - 1);
                            this.$message.error("最多两位小数!");
                        }
                    } else if (dot == -1) {

                    }
                }
            },
            //搜索房间号
            queryTenantRoom() {
                this.queryRoom();
            },
            //查询汇总账单
            queryH() {
                return this.$fly.post(queryEquallySharedCollectUrl, {
                    "regionCode": this.$vc.getCurrentRegion().code, // 园区code 必填
                    "startDate": "",  // 开始日期
                    "endDate": "", // 结束日期
                    "createUserId": "", // 创建用户
                    "collectName": "",  // 公摊名称
                    "pageNum": 1,  // 分页参数 页数  必填
                    "pageSize": 9999,  // 分页参数 每页条数  必填
                    "type": this.isElectricty ? 2 : 1 //1水 2电
                }).then(res => {
                    if (res.code != 0) {
                        return
                    }
                    this.inforList = res.data.list;
                })
            },
            //保存
            editRoomHy() {
                if(this.isEditRoomHy == true){
                    clearTimeout(this.isEditRoomHyTimer);
                    this.isEditRoomHyTimer = setTimeout(() => {this.isEditRoomHy = false;console.log('亲情树');},1000)
                    return
                }
                this.isEditRoomHy = true;
                console.log('点击保存');
                if (this.dialogTitle == '修改水费公摊账单' || this.dialogTitle == '修改电费公摊账单') {
                    delete this.editRoomInfo.lastModifyUserId;
                    delete this.editRoomInfo.lastModifyUserName;
                    delete this.editRoomInfo.billPayCostUserName;
                    delete this.editRoomInfo.billPayCostUserId;
                    delete this.editRoomInfo.billCreateTime;
                    delete this.editRoomInfo.billLastModifyTime;
                    this.$fly.put(modifyEquallySharedBillUrl, {
                        ...this.editRoomInfo,
                        billAmount: this.billAmountData || this.billAmount,
                        billStartDate: this.editRoomInfo.billStartDate + ' ' + '00:00:00',
                        billEndDate: this.editRoomInfo.billEndDate + ' ' + '23:59:59',
                        equallySharedTotalAmount: this.equallySharedTotalAmount,
                        equallySharedPriceAvg: this.equallySharedPriceAvg,
                        equallySharedSpace: this.totalSpace,//水费公摊总面积
                        id: this.id
                    }).then(res => {
                        if (res.code != 0) {
                            return
                        }
                        this.$vc.toast(`修改成功`);
                        this.$vc.emit('queryBillWarter', {})
                        this.addRoomVisibleHy = false;
                    })
                } else if (this.dialogTitle == '添加水费公摊账单' || this.dialogTitle == '添加电费公摊账单') {
                    console.log('进入添加');
                    this.$fly.post(addEquallySharedBillUrl, {
                        ...this.editRoomInfo,
                        billAmount: this.billAmount,
                        billStartDate: this.editRoomInfo.billStartDate + ' ' + '00:00:00',
                        billEndDate: this.editRoomInfo.billEndDate + ' ' + '23:59:59',
                        equallySharedTotalAmount: this.equallySharedTotalAmount,
                        equallySharedPriceAvg: this.equallySharedPriceAvg,
                        equallySharedSpace: this.totalSpace//水费公摊总面积
                    }).then(res => {
                        if (res.code != 0) {
                            return
                        }
                        this.$vc.toast(`添加成功`);
                        this.$vc.emit('queryBillWarter', {})
                        this.addRoomVisibleHy = false;
                    })
                }

            },
            changeBuilding() {
                console.log('触发选择');
                console.log(this.infor.equallySharedSpace);
                this.editRoomInfo.equallySharedCollectId = this.infor.id;//公摊汇总表ID
                this.editRoomInfo.equallySharedCollectName = this.infor.equallySharedName;//公摊汇总名称
                // this.editRoomInfo.alreadyPayState = this.infor.alreadyPayState;//工单缴费状态
                this.editRoomInfo.billGenerateWay = 1;//账单产生方式，改接口恒等于1
                this.editRoomInfo.billStartDate = this.infor.collectBillStartDate;
                this.editRoomInfo.billEndDate = this.infor.collectBillEndDate;
                this.editRoomInfo.equallySharedDosage = this.infor.equallySharedDosage;
                this.editRoomInfo.equallySharedPrice = this.infor.equallySharedPrice;
                //this.editRoomInfo.equallySharedTotalAmount = this.infor.equallySharedTotalAmount;
                //this.editRoomInfo.roomSpace = this.infor.equallySharedSpace;
                this.totalSpace = this.infor.equallySharedSpace;
                this.editRoomInfo.equallySharedPriceAvg = this.infor.equallySharedPriceAvg;
                //this.editRoomInfo.billState = this.infor.isDelete == 1 ? 2 : this.infor.billState;
            },
            // 点击下拉框选中
            addCompanyName(companyName, lessedId) {
                console.log(companyName, lessedId);
                this.editRoomInfo.assumeLesseeName = companyName;
                this.editRoomInfo.assumeLesseeId = lessedId;
                this.tenantVisible = false;
            },
            addCompanyNameRoom(roomNo, item) {
                this.editRoomInfo.roomNo = roomNo;//房间号
                this.editRoomInfo.roomId = item.roomId;//房间ID
                this.editRoomInfo.roomSpace = item.space;//房间面积
                this.editRoomInfo.buildingName = item.buildingName;//楼栋名称
                this.editRoomInfo.buildingCode = item.buildingId;//楼栋ID
                this.editRoomInfo.floor = item.floor;
                this.tenantRoom = false;
            },
            //查询企业
            queryTenant() {
                this.$fly
                    .post(queryTenantUrl, {
                        regionId: this.$vc.getCurrentRegion().code,
                        keyword: this.keyword,
                    })
                    .then((res) => {
                        if (res.code != 0) {
                            return;
                        }
                        this.lesseeList = res.data.datas;
                    });
            },
            //查询所有房间
            queryRoom() {
                this.$fly
                    .post(queryRoomRentUrls, {
                        regionId: this.$vc.getCurrentRegion().communityId,
                        ...this.queryRooms,
                    }).then(res => {
                    if (res.code != 0) {
                        return
                    }
                    console.log('房间信息');
                    console.log(res.data);
                    this.roomList = res.data.datas;
                })
            }
        }
    }
</script>

<style scoped lang="stylus">
    .room-form {
        padding: 27px 30px 10px;

        .field-item {
            margin-bottom: 22px;
            font-size: 0;

            &-label {
                font-size: 24px;
                text-align: right;
                width: 208px;
                margin-right: 40px;
                display: inline-block;
                vertical-align: middle;
                line-height: 1;
                margin-bottom: 0;
            }

            &-content {
                display: inline-block;
                vertical-align: middle;
                font-size: 24px;

                input {
                    width: 400px;
                    height: 40px;
                    text-indent: 5px;
                    border-radius: 4px;
                    border: 1px solid #979797;

                    &::placeholder {
                        color: #999;
                    }
                }

                select {
                    width: 220px;
                    text-indent: 5px;
                }
            }
        }

        .tips {
            color: #999;
            font-size: 20px;
        }

        .field-font {
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 33px;
            color: #999999;
        }
    }

    .location-search {
        width: 100%;
        display: flex;
    }

    .location-search .location-input {
        border-radius: 4px;
        border: 1px solid #999;
        padding: 0 10px;
        vertical-align: middle;
        width: 30px;
        height: 40px;
        box-sizing: border-box;
    }

    .font {
        font-size: 20px;
        vertical-align: middle;
        margin-left: 30px;
        float: right;
        height: 40px;
        padding: 0;
        width: 60px;
        line-height: 40px;
        box-shadow: 0 5px 10px 0 rgba(0, 182, 148, 0.3);
    }

    .selectDive {
        padding: 0;
        width: 20px;
        height: 20px;
        border: 1px solid black;
        display: inline-block;
        border-radius: 3px;
        margin-right: 5px;
        position: relative;
        background-image: "../../assets/未选中.png"
    }

    input[type='checkbox'] {
        width: 0px !important
        display inline-block
        opacity 1
        margin-right 25px
        height 20px !important
    }

    .roomli {
        display flex
        align-items center
    }

    .roomMove {
        cursor pointer
    }

    .field-item-label {
        width 220px !important
    }
</style>
<style>
    .summarCollection-container .el-input__inner {
        padding-right: 10px !important;
    }

    .summarCollection-container .el-icon-caret-bottom {
        padding-top: 4px !important;
    }

    .jsF .el-icon-caret-bottom {
        padding-top: 0px !important;
    }
</style>